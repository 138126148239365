const norwayConfig = {
  operatorUUID: '5336003e-0105-4402-809f-93bf6498af34',
  theme: 'evo-norway',
  logo: 'logo.svg',
  currency: 'NOK',
  showRegions: true,
  paymentMethods: ['credit_card'],
  mobilePrefix: '+47',
  termsURL: 'https://evofitness.no/medlemsvilkar/',
  locales: {
    all: ['nb-NO'],
    defaultLocale: 'nb-NO'
  },
  tracking: {
    gtm: {
      id: 'GTM-5XFH42'
    },
    adtraction: {
      c: 'NOK',
      tp: '1173057178',
      ap: '1173056991'
    }
  },
  dibs: {
    callbackURL:
      'https://dibs-api.production.credlock.net/5336003e-0105-4402-809f-93bf6498af34/signup'
  },
  mypage: {
    url: 'https://me.evofitness.no'
  }
}

export default norwayConfig
