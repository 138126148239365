import React from 'react'

import LocaleSwitcher from './LocaleSwitcher'

const Logo = ({ config }) => {
  const path = `/themes/${config.theme}/${config.logo || 'logo.png'}`

  return (
    <header className='logo'>
      <img src={path} className='logo' alt='logo' />
      <LocaleSwitcher />
    </header>
  )
}

export default Logo
