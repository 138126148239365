import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import ErrorBoundary from './components/ErrorBoundary'
import Gtm from './components/GTM'
import Layout from './components/Layout'
import SignupForm from './scenes/SignupForm'
import VerifyCode from './scenes/VerifyCode'
import InvoicePayment from './scenes/InvoicePayment'
import ProcessingPayment from './scenes/ProcessingPayment'
import Welcome from './scenes/Welcome'
import CreditCard from './scenes/CreditCard'
import QuoteContext from './components/Quote/context'
import withConfig from './services/withConfig'
import { v4 as uuidV4 } from 'uuid'
import './App.css'

class App extends Component {
  constructor (props) {
    super(props)

    // Re-load quote from session storage to support back/re-direct from DIBS payment page and reloads
    const {
      uuid,
      code,
      email,
      verified,
      mobile,
      paymentMethod,
      currentPayment,
      nextPayment,
      presentation
    } = sessionStorage.getItem('quote')
      ? JSON.parse(sessionStorage.getItem('quote'))
      : {}

    // Store current quote globally as it's used in multiple components.
    // Provided & consumed using React Context API
    this.state = {
      mypageAccessKey: null,
      quote: {
        // Required on welcome page for tracking codes
        uuid: uuid || uuidV4(),
        code: code || null,
        email: email || null,
        mobile: mobile || null,

        // Required to determine if phone verification is needed/completed
        verified: verified || false,

        // Required on payment processing page to check if required to
        // invoke API to complete signup
        paymentMethod: paymentMethod || null,

        // Required on various pages to display quote
        currentPayment: currentPayment || null,
        nextPayment: nextPayment || null,
        presentation: presentation || null,

        // Allows nested child components to update state
        updateGlobalQuote: (newQuote) => {
          // Ensure prev state is not overwritten - React does not do a deep merge
          this.setState(
            (prevState, props) => ({
              quote: {
                uuid: newQuote.uuid,
                code: newQuote.code,
                email: newQuote.email,
                mobile: newQuote.mobile,
                paymentMethod: newQuote.paymentMethod,
                currentPayment: newQuote.currentPayment,
                nextPayment: newQuote.nextPayment,
                presentation: newQuote.presentation,
                requireVerification: newQuote.requireVerification,

                updateGlobalQuote: prevState.quote.updateGlobalQuote
              }
            }),
            () => {
              sessionStorage.setItem('quote', JSON.stringify(this.state.quote))
            }
          )
        }
      }
    }
  }

  render () {
    const { config } = this.props

    return (
      <ErrorBoundary>
        <Layout config={config}>
          <Gtm id={config.tracking.gtm.id} />

          <QuoteContext.Provider value={this.state.quote}>
            <Switch>
              <Route
                path='/profile'
                render={(props) => {
                  return (
                    <SignupForm
                      quoteUUID={this.state.quote.uuid}
                      paymentMethods={config.paymentMethods}
                      mobilePrefix={config.mobilePrefix}
                      operatorUUID={config.operatorUUID}
                      dibsCallbackURL={this.props.config.dibsCallbackURL}
                      {...props}
                    />
                  )
                }}
              />
              <Route
                path='/verify-code'
                component={() => (
                  <VerifyCode
                    config={config}
                    quote={this.state.quote}
                    quoteUUID={this.state.quote.uuid}
                    operatorUUID={config.operatorUUID}
                  />
                )}
              />
              <Route path='/invoice-payment' component={InvoicePayment} />
              <Route path='/credit-card' component={() => <CreditCard />} />
              <Route
                path='/processing-payment'
                component={() => (
                  <ProcessingPayment
                    config={config}
                    quote={this.state.quote}
                    quoteUUID={this.state.quote.uuid}
                    operatorUUID={config.operatorUUID}
                    paymentMethod={this.state.quote.paymentMethod}
                  />
                )}
              />
              <Route path='/welcome' component={() => <Welcome />} />
              <Route
                path='/'
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: '/profile'
                    }}
                  />
                )}
              />
            </Switch>
          </QuoteContext.Provider>
        </Layout>
      </ErrorBoundary>
    )
  }
}

export default withConfig(App)
